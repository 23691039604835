<template>
    <v-autocomplete
        v-model="value"
        outlined
        dense
        v-bind="$attrs"
        :items="items"
        hide-details
        placeholder="School Year"
    />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    inheritAttrs: false,
    computed: {
        ...mapGetters('schoolYear', ['getSelected', 'getItems']),
        ...mapGetters({ lea: 'getDistrictLea' }),
        value: {
            get() {
                return this.getSelected
            },
            set(year) {
                this.select(year)
            },
        },
        items() {
            return this.getItems.map((y) => y.schoolYear)
        },
    },
    async mounted() {
        await this.get({ lea: this.lea })
        if (this.getItems.length) {
            this.select(this.getItems[this.getItems.length - 1].schoolYear)
        }
    },
    methods: {
        ...mapActions('schoolYear', ['select', 'get']),
    },
}
</script>
