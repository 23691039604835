<template>
    <child-layout title="Table Viewer">
        <v-row>
            <v-col sm="1">
                <school-year-picker class="mt-2" />
                <v-list dense>
                    <v-list-item-group v-model="selectedItem" color="primary">
                        <v-list-item
                            v-for="(item, i) in items"
                            :key="i"
                            link
                            :to="{ name: item.route }"
                        >
                            <v-list-item-content>
                                <v-list-item-title v-text="item.title"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-col>
            <v-col>
                <router-view />
            </v-col>
        </v-row>
    </child-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import ChildLayout from '@/components/layout/ChildLayout'
import SchoolYearPicker from '@/components/SchoolYearPicker.vue'

export default {
    components: { ChildLayout, SchoolYearPicker },
    data() {
        return {
            pageTitle: 'Table Viewer',
            selectedItem: null,
            items: [
                { title: 'Attendance', route: 'attendance' },
                { title: 'Behavior', route: 'behavior' },
                { title: 'Contacts', route: 'contacts' },
                { title: 'Courses', route: 'courses' },
                { title: 'Enrollment', route: 'enrollment' },
                { title: 'Gradebooks', route: 'gradebooks' },
                { title: 'Grading Periods', route: 'grading-periods' },
                { title: 'Report Card', route: 'report-cards' },
                { title: 'Rosters', route: 'roster' },
                { title: 'Sections', route: 'sections' },
                { title: 'Sites', route: 'sites' },
                { title: 'Staff', route: 'staff' },
                { title: 'Students', route: 'students' },
                { title: 'Student Programs', route: 'student-programs' },
                { title: 'Student Tags', route: 'student-tags' },
                { title: 'Transcripts', route: 'transcripts' },
            ],
        }
    },
    methods: {
        routeName(routeName) {
            const route = this.$router.resolve({
                params: { districtId: this.getDistrictId() },
                name: routeName,
            })

            return route.href
        },
        ...mapGetters(['getDistrictId']),
    },
}
</script>
